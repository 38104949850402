import { auth } from './firebase';
import { db } from '../firebase';

const CLOUD_FUNCTION_ENDPOINT = 'https://us-central1-medipics-dev.cloudfunctions.net/api/virgil-jwt';
let _publicKeys;

async function fetchToken(authToken) {
  const response = await fetch(
    CLOUD_FUNCTION_ENDPOINT,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      })
    },
  );
  if (!response.ok) {
    throw `Error code: ${response.status} \nMessage: ${response.statusText}`;
  }
  return response.json().then(data => data.token);
};

// Sign Up
export const doCreateUserWithEmailAndPassword = async (email, password) => {
  // Create new user in firebase
  await auth.createUserWithEmailAndPassword(email, password);

}

// Sign In
export const doSignInWithEmailAndPassword = async (email, password) => {
  await auth.signInWithEmailAndPassword(email, password);
  var subscription = await db.onceGetUserSubscription();

  if (subscription.isActive === undefined && subscription.active === undefined){
    throw 'Subscription unavailable';
  } else if (subscription.isActive !== undefined && subscription.isActive !== null && !subscription.isActive) {
    throw 'Subscription has expired';
  } else if (subscription.active !== undefined && subscription.active !== null && !subscription.active) {
    throw 'Subscription has expired';
  }
}

// Sign Out
export const doSignOut = async () => {
  // firebase sign out
  auth.signOut();
}

// Password Reset
export const doPasswordReset = (email) =>
  auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = async (oldPassword, newPassword) => {
  auth.currentUser.updatePassword(newPassword);
}
