import React from 'react';
import { Link } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';
import SignOutButton from './SignOut';
import * as routes from '../constants/routes';

var queryString = window.location.search;

const Navigation = () =>
  <AuthUserContext.Consumer>
    {authUser => authUser ? <NavigationAuth /> : <NavigationNonAuth />}
  </AuthUserContext.Consumer>

const NavigationAuth = () =>
  <nav className="navbar navbar navbar-expand-lg navbar-dark bg-primary justify-content-between">
    <span className="navbar-brand"><img width="30" height="30" className="d-inline-block align-top pull-left" alt="" src="https://viewer.medipics.net/images/icon.png" />&nbsp; Medipics</span>
    <ul className="nav navbar-nav">
      <li><Link className="nav-link" to={routes.IMAGE_VIEWER}>Image Viewer</Link></li>
      <li><Link className="nav-link" to={routes.ACCOUNT}>Account</Link></li>
      <li><Link className="nav-link patients" to={routes.PATIENTS}>Add Patient</Link></li>
      <li><a href="https://medipics.net/support-request/" target="_blank">Support</a></li>
    </ul>
    <SignOutButton />
  </nav>

const NavigationNonAuth = () =>
  <nav className="navbar navbar navbar-expand-lg navbar-dark bg-primary">
    <span className="navbar-brand"><img width="30" height="30" className="d-inline-block align-top pull-left" alt="" src="https://viewer.medipics.net/images/icon.png" />&nbsp; Medipics</span>
    <div className="container-fluid">
      <ul className="nav navbar-nav">
        <li><Link to={routes.SIGN_IN + queryString}>Sign In</Link></li>
        <li><a href="https://medipics.net/support-request/" target="_blank">Support</a></li>
      </ul>
    </div>
  </nav>

export default Navigation;
