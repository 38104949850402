import React, { Component } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';

import { PasswordForgetLink } from './PasswordForget';
import { authenticate } from '../firebase';
import * as routes from '../constants/routes';
import { db } from '../firebase';

const SignInPage = ({ history }) =>
  <div className="container">
    <SignInForm history={history} />
  </div>

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = async (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    authenticate.doSignInWithEmailAndPassword(email, password)
      .then(async (authorizedUser) => {
        this.setState({ ...INITIAL_STATE });
        history.push(routes.LANDING + window.location.search);
      })
      .catch(error => {
        authenticate.doSignOut();
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    const marketingWebsiteUri = "https://medipics.net/" + window.location.search;

    return (
      <div id="sign_in_form" className="panel panel-primary">
        <div className="panel-heading">
          <h3 className="panel-title">Sign In</h3>
        </div>
        <div className="panel-body">
          <form onSubmit={this.onSubmit}>
            <div className="form-group row">
              <div className="col-sm-11">
                <input
                  className="login-textbox"
                  value={email}
                  onChange={event => this.setState(byPropKey('email', event.target.value))}
                  type="text"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-11">
                <input
                  className="login-textbox"
                  value={password}
                  onChange={event => this.setState(byPropKey('password', event.target.value))}
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="offset-sm-2 col-sm-11">
                <button className="btn btn-primary login-button" disabled={isInvalid} type="submit">
                  <span className="glyphicon glyphicon-log-in" aria-hidden="true"></span>
                  &nbsp;Sign In
        </button>
              </div>
            </div>
            {error && <p>{error.message}</p>}
          </form>
          { <p>
            New to Medipics? <a target="_blank" href={marketingWebsiteUri}>Click Here to register</a>
          </p> }
          {<PasswordForgetLink />}
        </div>
      </div>
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};
