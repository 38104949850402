import React, { Component } from 'react';

import { authenticate } from '../firebase';
import AuthUserContext from './AuthUserContext';
import withAuthorization from './withAuthorization';
import { db } from '../firebase';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  accountNumber: '',
  error: null,
};

const SUCCESS_SEND = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  accountNumber: '',
  error: null,
  success: 'Patient successfully created.'
};

class AddPatientForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.firstNameRef = React.createRef();
  }

  async componentDidMount() {
    this.firstNameRef.current.focus();
  }

  onSubmit = async (event) => {
    const { firstName } = this.state;
    const { lastName } = this.state;
    const { dateOfBirth } = this.state;
    const { accountNumber } = this.state;

    let currentUserId = db.getCurrentUserId();
    debugger;
    console.log(firstName);
    console.log(lastName);
    console.log(dateOfBirth);
    console.log(accountNumber);
    await db.collection('patients').add({
      accountNumber: accountNumber,
      birthdate: new Date(dateOfBirth).toLocaleDateString('en-US'),
      firstName: firstName,
      lastName: lastName,
      userID: currentUserId
    })
    .then(async (docRef) => {
      console.log("did it work?");
      /*functions.logger.info("Document written with ID: ", docRef.id);
      patientDbId = docRef.id;
  
      let currentUserRef = await admin.firestore().collection('users').doc(currentUserId);
      await currentUserRef.update({
        patients: admin.firestore.FieldValue.arrayUnion(patientDbId)
      });*/
  
      return true;
    });

    event.preventDefault();
  }

  render() {
    const {
      firstName,
      lastName,
      dateOfBirth,
      accountNumber,
      error,
      success,
    } = this.state;

    const isInvalid = firstName == "" || lastName == "" || dateOfBirth == "" || accountNumber == "";

    return (
        <div id="add_patient_form" className="panel panel-primary">
          <div className="panel-heading">
            <h3 className="panel-title">Add Patient</h3>
          </div>
          <div className="panel-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">First Name:</label>
                <div className="col-sm-8">
                  <input
                    ref={this.firstNameRef}
                    className="form-control"
                    value={firstName}
                    onChange={event => this.setState(byPropKey('firstName', event.target.value))}
                    type="text"
                    placeholder="First name"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Last Name:</label>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    value={lastName}
                    onChange={event => this.setState(byPropKey('lastName', event.target.value))}
                    type="text"
                    placeholder="Last name"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Date of Birth:</label>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    value={dateOfBirth}
                    onChange={event => this.setState(byPropKey('dateOfBirth', event.target.value))}
                    type="date"
                    placeholder="Date of Birth"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Account Number</label>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    value={accountNumber}
                    onChange={event => this.setState(byPropKey('accountNumber', event.target.value))}
                    type="text"
                    placeholder="Account number"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="offset-sm-2 col-sm-10">
                  <button className="btn btn-primary" disabled={isInvalid} type="submit">
                    Add Patient
        </button>
                </div>
              </div>
              {error && <p><br></br>{error.message}</p>}
        {success && <p><br></br>{success}</p>}
            </form>
          </div>
        </div>
    );
  }
}

export default AddPatientForm;
