import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';

import withAuthorization from './withAuthorization';

class Visits extends Component {
    constructor(props)
    {
        super(props);

        this.state = {
            visits: props.visitList,
            patientId: props.patientId,
        };
    }

    render() {
        return (!!this.state.visits &&
            <div>
                {Object.keys(this.state.visits).map(key =>
                    <div key={"visit" + key}><Link to={routes.IMAGE_VIEWER + "/" + key + "/" + this.state.patientId}>Visit on: {new Date(this.state.visits[key].timestamp * 1000).toLocaleDateString()}</Link> </div>
                    )
                }
            </div>
        );
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Visits);