import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { authenticate } from '../firebase';
import * as routes from '../constants/routes';

const PasswordForgetPage = () =>
  <div className="container">
    <PasswordForgetForm />
  </div>

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

const SUCCESS_SEND = {
  email: '',
  error: null,
  success: 'Your password reset email has been sent.'
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    authenticate.doPasswordReset(email)
      .then(() => {
        this.setState({ ...SUCCESS_SEND });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      error,
      success
    } = this.state;

    const isInvalid = email === '';

    return (
      <div id="reset_password_form" className="panel panel-primary">
        <div className="panel-heading">
          <h3 className="panel-title">Forgot Password</h3>
        </div>
        <div className="panel-body">
      <form className=" navbar-left" onSubmit={this.onSubmit}>
        <div className=" col-sm-11 form-group">
        Please enter your email address. You will receive an email message with instructions on how to reset your password.<br/><br/>
        
          <input
            className="form-control"
            value={this.state.email}
            onChange={event => this.setState(byPropKey('email', event.target.value))}
            type="text"
            placeholder="Email address"
          /><br/>
          <button className="btn btn-primary" disabled={isInvalid} type="submit">
            Get New Password
        </button>

        {error && <p><br></br>{error.message}</p>}
        {success && <p><br></br>{success}</p>}
          
        </div>
      </form>
      </div>
      </div>
    );
  }
}
const PasswordForgetLink = () =>
  <p> Forgot your Password? <Link to={routes.PASSWORD_FORGET}>Click here to reset</Link>
  </p>

export default PasswordForgetPage;

export {
  PasswordForgetForm,
  PasswordForgetLink,
};
