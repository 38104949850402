import React, { Component } from 'react';
import Thumbnails from './Thumbnails.js';
import withAuthorization from './withAuthorization';
import { db } from '../firebase';
import ImageView from './ImageView.js';
import ImageCompare from './ImageCompare.js';
import ImageUploader from './ImageUploader.js';
import ImageDownloader from './ImageDownloader.js';
import { Modal } from 'react-bootstrap';
import { Collapse } from 'react-bootstrap';
import ReactImageMagnify from 'react-image-magnify';
import Select from 'react-select/async';
import { db1 } from '../firebase/firebase';
import firebase from "firebase";
import image from 'react-firebase-file-uploader/lib/utils/image';


const patientPlaceholderText = 'Select Patient...';
const visitsPlaceholderText = 'All Visits';
let patientOptions = [];
let visitOptions = [];
let unsubscribes = [];
let patientSubscribe = null;
let uriPatientId = null;
let uriPractitionerId = null;
let patientPlaceHolder = patientPlaceholderText;
let visitsPlaceHolder = visitsPlaceholderText;

class ImageViewerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visitDbId: -1,
            visitDate: "",
            visitList: [],
            patientDbId: -1,
            images: null,
            imageViews: [],
            leftImageUrl: "",
            rightImageUrl: "",
            currentImageLeft: true,
            imageModeIndex: 0,
            show: false,
            popupUrl: "",
            patients: [],
            open: true,
            selectedVisitOption: null,
            tracknetPatientLoaded: false,
        }

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            firebase.functions().useFunctionsEmulator('http://localhost:5001')
        }

        const url = new URL(window.location.href);
        uriPatientId = url.searchParams.get('Patient_ID');
        uriPractitionerId = url.searchParams.get('Practitioner_ID');
        
        this.patentSelectRef = React.createRef();
    }

    async componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);
        if(this.patientSelectRef != null)
        {
         this.patientSelectRef.current.focus();
        }
    }

    _handleKeyDown = (event) => {
        var ESCAPE_KEY = 27;
        switch (event.keyCode) {
            case ESCAPE_KEY:
                this.deSelectPatient();
                break;
            default:
                break;
        }
    }

    unsubscribeFromAll = () => {
        if (unsubscribes !== null) {
            for (var i = 0; i < unsubscribes.length; i++) {
                if (typeof unsubscribes[i] === 'function')
                    unsubscribes[i]();
            }
        }
    }

    containsObject = (obj, list) => {
        var i;
        if (list === null)
            return false;

        for (i = 0; i < list.length; i++) {
            if (list[i].patientDbId === obj.patientDbId) {
                return true;
            }
        }

        return false;
    }

    handleClose = () => {
        debugger;
        this.setState({ show: false });
    }

    handleShow = (imageUrl, e) => {
        this.setState({ show: true });
        this.setState({ popupUrl: imageUrl });
    }

    //#region Image handlers
    subscribeToImageUpdates = async (patientId) => {
        visitOptions.forEach((visit) => {
            if (visit.value === -1)
                return;

            let unsubscribe = db1.collection('patients').doc(patientId).collection('visits').doc(visit.value).collection('photos')
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    var imageData = change.doc.data();
                    if (change.type === 'added') {
                        imageData.visitDate = "";
                        imageData.visitId = this.state.visitDbId;

                        if (this.state.visitDbId !== -1)
                        {
                            for (var i = 0; i < this.state.visitList.length; i++)
                            {
                                if (this.state.visitList[i].id == this.state.visitDbId)
                                {
                                    imageData.visitDate = this.state.visitList[i].timestamp;
                                }
                            }
                        }
                        else
                        {
                            let currentVisitDate = Math.max.apply(Math, this.state.visitList.map(function(o) { return o.timestamp; }))
                            imageData.visitDate = currentVisitDate;
                        }

                        this.loadNewImage(imageData);
                    }
                    if (change.type === 'removed') {
                        this.removeVisitImage(imageData);
                    }
                });
            });
            unsubscribes.push(unsubscribe);
        });
    }

    loadVisitImages = async(selectedVisitOption) => {
        if (selectedVisitOption === undefined || selectedVisitOption === null || selectedVisitOption.id === '-1')
            return;
        await this.loadImages(selectedVisitOption);
    }

    loadNewImage = async(imageData) => {
        let visitImageList = this.state.images;

        let containsImage = (image) => image.url === imageData.url;
        let hasImage = visitImageList.some(containsImage);

        if (visitImageList !== null && !hasImage) {
            visitImageList.push(imageData);
            this.setState({ images: visitImageList });
        }
    }

    removeVisitImage = async(imageData) => {
        let visitImageList = this.state.images;

        let filterOutImage = (image) => image.url !== imageData.url;
        let filteredImageList = visitImageList.filter(filterOutImage);

        visitImageList = filteredImageList;
        this.setState({ images: filteredImageList });
    }

    loadImages = async(visitOption) => {
        let visitId = visitOption.value;
        let visitImageList = [];
        let selectedPatientDbId = this.state.patientDbId;
        let visitImages = await db.getVisitImages(selectedPatientDbId, visitId);

        visitImages.forEach(async visitPhoto => {
            visitPhoto.imageUrl = visitPhoto.url;
            visitImageList.push(visitPhoto);
        });
        this.setState({ images: visitImageList });
    }

    changeImageMode = (tabIndex, e) => {
        this.setState({ imageViews: [] });
        this.setState({ imageModeIndex: tabIndex })
    }

    // FIXME: (refactor this)
    injectImage = async (imageUrl, imageTitle, e) => {
        var imageList = null;

        // Do whatever with the img
        if (this.state != null && this.state.imageViews) {
            imageList = this.state.imageViews;

            for (var i = 0; i < imageList.length; i++) {
                if (imageList[i].imageUrl === imageUrl) {
                    return;
                }
            }

            if (this.state.currentImageLeft) {
                this.setState({ leftImageUrl: imageUrl });
                this.setState({ currentImageLeft: false });
            } else {
                this.setState({ rightImageUrl: imageUrl });
                this.setState({ currentImageLeft: true });
            }
        } else {
            imageList = [];
        }
        if (this.state.imageModeIndex === 0) {
            imageList.push({ imageUrl: imageUrl, imageTitle: imageTitle });
            this.setState({ imageViews: imageList });
        }
    }

    removeImage = (imageUrl, e) => {
        var imageURLs = null;

        if (this.state !== null && this.state.imageViews) {
            imageURLs = this.state.imageViews;
        } else {
            imageURLs = [];
        }

        for (var i = 0; i < imageURLs.length; i++) {
            if (imageUrl === imageURLs[i].imageUrl) {
                imageURLs.splice(i, 1);
                break;
            }
        }

        this.setState({ imageViews: imageURLs });
    }

    deSelectPatient = () => {
        this.selectPatient(-1);
        this.selectVisit(-1);
        patientPlaceHolder = patientPlaceholderText;
        visitsPlaceHolder = visitsPlaceholderText;
    }

    clearImages = () => {
        this.setState({ leftImageUrl: "" });
        this.setState({ rightImageUrl: "" });
        this.setState({ currentImageLeft: true });
        this.setState({ imageViews: [] });
        this.setState({ images: null });
    }
    //#endregion

    //#region Visit handlers
    promiseVisitOptions = (value) => {
        let selectedPatientDbId = this.state.patientDbId;
        let visits = null;

        if (selectedPatientDbId === null || selectedPatientDbId === "" || selectedPatientDbId === -1)
            return;

        return new Promise(async resolve => {
            visits = await db.getPatientVisits(selectedPatientDbId);
            if (visits !== null)
            {
                Object.keys(visits).map(key => {
                    this.loadNewVisit(visits[key]);
                });
            }

            await this.loadVisitImages(visitOptions[0]);
            await this.subscribeToVisitUpdates(selectedPatientDbId);
            await this.subscribeToImageUpdates(selectedPatientDbId);

            resolve(visitOptions);
        });
    }

    subscribeToVisitUpdates = async (patientDbId) => {
        if (patientDbId === -1)
            return;

        var visitUnsubscribe = db1.collection('patients').doc(patientDbId).collection('visits')
        .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                var visitData = change.doc.data();
                visitData.id = change.doc.id;
                if (change.type === 'added') {
                    let visitList = this.state.visitList;
                    let containsVisit = (visit) => visit.id === visitData.id;
                    let hasVisit = visitList.some(containsVisit);

                    if (!hasVisit) {
                        // New visit added, set it as the default option to auto select it in the dropdown
                        this.setState({visitDbId: visitData.id});
                        this.setState({selectedVisitOption: { value: visitData.id, label: new Date(visitData.timestamp * 1000).toLocaleDateString() }});
                        this.reloadvisits();
                    }
                }
                if (change.type === 'removed') {
                    this.reloadvisits();
                }
            });
        });
        unsubscribes.push(visitUnsubscribe);
    }

    visitSelectChanged = async (selectedVisitOption) => {
        this.clearImages();
        this.setState({selectedVisitOption: selectedVisitOption});
        this.setState({visitDbId: selectedVisitOption.value});
        await this.loadVisitImages(selectedVisitOption);
    }

    removeVisit = async (visitData) => {
        let visitList = this.state.visitList;

        let filterOutVisit = (visit) => visit.id !== visitData.id;
        let filteredVisitList = visitList.filter(filterOutVisit);

        this.setState({visitList: filteredVisitList});
    }

    loadNewVisit = async(visitData) => {
        if (visitOptions.length === 0)
            visitOptions.push({ value: -1, label: "All Visits" });

        let visitList = this.state.visitList;

        let containsVisit = (visit) => visit.id === visitData.id;
        let hasVisit = visitList.some(containsVisit);

        if (visitList !== null && !hasVisit) {
            visitList.push(visitData);
            visitOptions.push({ value: visitData.id, label: new Date(visitData.timestamp * 1000).toLocaleDateString() });
        }
    }

    reloadvisits = async() => {
        // This is a bit Janky but it's the only way I found to re-load the visits
        let patientDbId = this.state.patientDbId;
        this.setState({ patientDbId: -1});
        this.setState({ patientDbId: patientDbId });
    }

    clearVisitList = async() => {
        let visitList = [];
        this.setState({ visitList: visitList});
        visitOptions = [];
    }
    //#endregion

    //#region Traknet hanlders
    selectTraknetPatient = async () => {
        let patientId = await db.getTracknetPatientId(uriPatientId);
        if (patientId !== null) {
            this.selectPatient(patientId);
            this.setState({tracknetPatientLoaded: true});
        }
    }
    //#endregion

    //#region Patient handlers
    promisePatientOptions = (value) => {
        if (value === undefined || value === "" || value.length >= 3) {
            this.clearImages();
            return new Promise(async resolve => {
                var patients = this.state.patients;
                patientOptions = [];

                if (patients.length === 0)
                    patients = await db.onceGetAllPatients();

                for (var i = 0; i < patients.length; i++) {
                    if (value === undefined || value === '') {
                        patientOptions.push({ value: patients[i].patientDbId, label: patients[i].accountNumber + ' : ' + patients[i].lastName.charAt(0) + ', ' + patients[i].firstName.charAt(0) + ' ' + patients[i].birthdate });
                    } else if (patients[i].accountNumber.startsWith(value) || patients[i].lastName.toLowerCase().startsWith(value.toLowerCase()) || patients[i].firstName.toLowerCase().startsWith(value.toLowerCase())) {
                        patientOptions.push({ value: patients[i].patientDbId, label: patients[i].accountNumber + ' : ' + patients[i].lastName.charAt(0) + ', ' + patients[i].firstName.charAt(0) + ' ' + patients[i].birthdate });
                    }
                }

                if (this.state.patientDbId !== null && this.state.patientDbId !== -1) {
                    let patientLabel = this.selectPatientLabel(this.state.patientDbId);

                    if (patientLabel !== null)
                        patientPlaceHolder = patientLabel;
                }

                resolve(patientOptions);
                this.setState({ patients: patients});
                this.subscribeToPatientUpdates();

                if (uriPatientId !== null && !this.state.tracknetPatientLoaded)
                    this.selectTraknetPatient();
            });
        }
    }

    subscribeToPatientUpdates = async () => {
        if (patientSubscribe !== null)
            patientSubscribe();

        var currentUserId = db.getCurrentUserId();
        patientSubscribe = db1.collection("patients").where('userID', '==', currentUserId)
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    let patientList = this.state.patients;
                    let addedPatient = change.doc.data();

                    if (change.type === "added") {
                        addedPatient.patientDbId = change.doc.id;
                        let containsPatient = (patient) => patient.patientDbId === addedPatient.patientDbId;
                        let hasPatient = patientList.some(containsPatient);

                        if (!hasPatient) {
                            patientList.push(addedPatient);
                            this.setState({ patients: patientList});
                            this.selectPatient(addedPatient.patientDbId);
                            this.reloadvisits();
                        }
                    }
                    if (change.type === "modified") {
                        debugger;
                    }
                    if (change.type === "removed") {
                        debugger;
                    }
                });
            });
    }

    patientSelectChanged = async (selectedOption) => {
        this.clearImages();
        this.clearVisitList();
        this.unsubscribeFromAll();
        let selectedPatientDbId = selectedOption.value;

        if (selectedPatientDbId !== undefined) {
            this.setState({ patientDbId: selectedPatientDbId});
        }
    }

    selectPatient = async (patientDbId) => {
        this.clearImages();
        this.unsubscribeFromAll();
        let selectedPatientDbId = patientDbId;
        if (selectedPatientDbId !== undefined) {
            this.setState({ patientDbId: selectedPatientDbId});
        }
    }

    selectVisit = async (visitOption) => {
        this.setState({ selectedVisitOption: visitOption});
    }

    selectPatientLabel = (value) => {
        let patients = this.state.patients;
        let result = null;
        let selectedPatient = patients.find(e => e.patientDbId === value);

        if (selectedPatient !== undefined && selectedPatient !== null)
            result = selectedPatient.accountNumber + ' : ' + selectedPatient.lastName.charAt(0) + ', ' + selectedPatient.firstName.charAt(0) + ' ' + selectedPatient.birthdate;

        return result;
    }
    //#endregion

    render() {
        const { images } = this.state;
        const { imageViews } = this.state;
        const { leftImageUrl } = this.state;
        const { rightImageUrl } = this.state;
        const { open } = this.state;
        const { visitId } = this.state;
        const { visitDbId } = this.state;
        const { patientDbId } = this.state;

        let body;

        if (this.state.imageModeIndex === 0) {
            body = <div id="ImageViewerContainer">
                
                    {Object.keys(imageViews).map(key =>
                        <ImageView key={"main_image" + key} imageUrl={imageViews[key].imageUrl} imageTitle={imageViews[key].imageTitle} action_remove={this.removeImage} action_popup={this.handleShow} />
                    )
                    }
                
            </div>;
        } else if (this.state.imageModeIndex === 1) {
            body = <div id="ImageViewerContainer">
                <div className="row">
                    <ImageCompare leftImageUrl={leftImageUrl} rightImageUrl={rightImageUrl} />
                </div>
            </div>;
        } else if (this.state.imageModeIndex === 2) {
            body = <div id="ImageViewerContainer">
                <div className="row">
                    <ImageUploader visitDbId={visitDbId} patientDbId={patientDbId} />
                </div>
            </div>;
        } else if (this.state.imageModeIndex === 3) {
            body = <div id="ImageViewerContainer">
                <div className="row">
                    <ImageDownloader patientId={patientDbId} visitId={visitDbId} />
                </div>
            </div>;
        }

        return (
            <div className="container-fluid">
                <Collapse in={this.state.open} className="col-md-12" >
                    <div id="example-collapse-text">
                        <form>
                            <div className="form-row">
                                <div className="col-md-6">
                                    <Select
                                        ref={this.patientSelectRef}
                                        key={JSON.stringify(this.state.patientDbId)}
                                        isSearchable={true}
                                        cacheOption
                                        defaultOptions
                                        placeholder={patientPlaceHolder}
                                        defaultValue={patientDbId}
                                        options={patientOptions}
                                        loadOptions={this.promisePatientOptions}
                                        onChange={this.patientSelectChanged}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <Select
                                        key={JSON.stringify(this.state.patientDbId)}
                                        cache={false}
                                        filterOption={() => (true)}
                                        defaultValue={this.state.selectedVisitOption}
                                        defaultOptions
                                        placeholder={visitsPlaceHolder}
                                        options={visitOptions}
                                        loadOptions={this.promiseVisitOptions}
                                        onChange={this.visitSelectChanged}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <button type="button" onClick={() => { this.deSelectPatient() }} className="btn btn-default" aria-label="Left Align" title="Reset Images">
                                        <a href="#" id="reset-button" role="button" title="Reset Images"><i className="glyphicon glyphicon-erase"></i></a>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <ul className="nav nav-tabs col-md-12" style={{paddingTop:10}} role="tablist">
                            <li className={"nav-item", (this.state.imageModeIndex === 0) ? "active" : ""}>
                                <a className="nav-link" data-toggle="tab" role="tab" href="#" onClick={() => this.changeImageMode(0, this)} >Image List</a>
                            </li>
                            <li className={"nav-item", (this.state.imageModeIndex === 1) ? "active" : ""}>
                                <a className="nav-link" data-toggle="tab" role="tab" href="#" onClick={() => this.changeImageMode(1, this)} >Image Compare</a>
                            </li>
                            <li className={"nav-item", (this.state.imageModeIndex === 2) ? "active" : ""}>
                                <a className="nav-link" data-toggle="tab" role="tab" href="#" onClick={() => this.changeImageMode(2, this)} >Image Upload</a>
                            </li>

                            <li className={"nav-item", (this.state.imageModeIndex === 3) ? "active" : ""}>
                                <a className="nav-link" data-toggle="tab" role="tab" href="#" onClick={() => this.changeImageMode(3, this)} >Download Images</a>
                            </li>
                        </ul>
                        <Thumbnails images={images} action_add={this.injectImage}></Thumbnails>
                    </div>
                </Collapse>
                <img id="myimg" />
                <i className="glyphicon glyphicon-menu-down"
                    onClick={() => this.setState({ open: !open })}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}></i>
                <div style={{paddingLeft:20}}>
                {body}
                <div style={{display: "none"}} id="loadingPatientMessage"><h3>Please wait while we load the patient...</h3>
                <div className="loader">Loading...</div>
                </div>
                
                </div>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactImageMagnify {...{
                            smallImage: {
                                alt: 'Wristwatch by Ted Baker London',
                                isFluidWidth: true,
                                src: this.state.popupUrl,
                            },
                            largeImage: {
                                src: this.state.popupUrl,
                                width: 1200,
                                height: 1800
                            }
                        }} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(ImageViewerPage);