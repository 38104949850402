import React from 'react';
import withAuthorization from './withAuthorization';
import ReactDOM from 'react-dom';
import ImageView from './ImageView';

class Thumbnails extends React.Component {

    nth = function(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }

    render() {
        const images = this.props.images;
        let visitNumber = 0;
        let visitImages = {};
        let visitDates = images != null ? [...new Set(images.map(item => item.visitDate))] : null;

        if (visitDates !== undefined && visitDates !== null)
        {
            visitDates.forEach((visitDate) => {
                let filterOutImages = (image) => image.visitDate === visitDate;
                visitImages[visitDate] = images.filter(filterOutImages);
            });
        }

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var totalVisits = visitDates !== null ? visitDates.length + 1 : 0;
        var loopCount = 0;

       return (
        <div className="container thumbnail-group">
            {!!visitImages &&
            <div className="row">
            <div className="row" style={{width: "max-content", paddingLeft: "18px", paddingRight: "3px"}}>
                {Object.keys(visitImages).map(key =>{
                    loopCount++;
                    visitNumber = visitImages[key][0].visitNumber + 1;
                    var visitDate = new Date(key * 1000);

                    var month = monthNamesShort[visitDate.getMonth()];
                    var day = visitDate.getDate();
                    var year = visitDate.getFullYear();

                    return(
                            <div key={"visit-" + key} style={{display: "block", border: "1px solid #cecece", float:"left", paddingLeft: "12px", paddingRight: "12px", minWidth: "264px", borderRadius: "3px", margin: "6px 3px 6px 3px", backgroundColor: "#FFFFFF"}}>
                            <div style={{clear: "both"}}>
                                <h4 style={{float: "left"}}>{month} {day}, {year} </h4>
                                <h4 style={{float: "right"}}>{visitNumber}{this.nth(visitNumber)} visit </h4>
                            </div>
                            <div style={{clear: "both"}}></div>
                            {Object.keys(visitImages[key]).map(visitKey =>{
                                return(
                                    <a href="#" key={"thumbnail-" + key + "-" + visitKey} style={{width: "120px", float: "left", display: "inline", height: "100%"}} title={"" } className="thumbnail" onClick={() => this.props.action_add(visitImages[key][visitKey].url, visitImages[key][visitKey].title + ', ' + new Date(visitImages[key][visitKey].timestamp * 1000).toLocaleDateString(), this)}>
                                        <img className="img-responsive" style={{minWidth:50 + "px", minHeight:80 + "px"}} src={visitImages[key][visitKey].url} alt="..." />
                                    </a>
                                );
                              })
                            }
                        </div>
                        )
                    }
                    )
                }
            </div>
            </div>
        }
        </div>
       );
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Thumbnails);