import React, { Component } from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import { db } from '../firebase';

class ImageUploader extends Component {
  state = {
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: ""
  };

  handleUploadStart = (file) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var y = e.target.result;
    }
    var x = reader.readAsArrayBuffer(file);
    this.setState({ isUploading: true, progress: 0 })
  };

  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = async filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    let visitId = this.props.visitDbId;

    if (visitId === -1) {
      visitId = await db.addNewVisit(this.props.patientDbId, Date.now() / 1000);
    }

    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => {
        if (this.props.visitDbId !== null && this.props.patientDbId !== null)
          db.addImageToVisit(this.props.patientDbId, visitId, "Uploaded Image", url, Date.now() / 1000);
      });
  };

  render() {
    return (
      <div>
        <div>
          <h3>Upload Patient Images <span className="glyphicon glyphicon-cloud-upload" aria-hidden="true"></span></h3>
        </div>
        <div>
          <form>
            <label>Select a patient and a visit and add images. If you want to create a new visit, select a patient and select 'All Visits' from the visit dropdown.</label>
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            <FileUploader
              accept="image/*"
              name="avatar"
              randomizeFilename
              storageRef={firebase.storage().ref("images")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
              className="btn btn-default"
              multiple
            />
          </form>
        </div>
      </div>
    );
  }
}

export default ImageUploader;