import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';

const devEnvironment = false;

var prodConfig = {
  apiKey: "AIzaSyAr_H-HCzNY_Mer_9RojzkBCxRFKNIqVWA",
  authDomain: "medipics-prod.firebaseapp.com",
  databaseURL: "https://medipics-prod.firebaseio.com",
  projectId: "medipics-prod",
  storageBucket: "medipics-prod.appspot.com",
  messagingSenderId: "542394855213"
};

var devConfig = {
  apiKey: "AIzaSyB_2cGVv-9Aiuk94odDSlFo17MiPkvwDf8",
  authDomain: "medipics-dev.firebaseapp.com",
  databaseURL: "https://medipics-dev.firebaseio.com",
  projectId: "medipics-dev",
  storageBucket: "medipics-dev.appspot.com",
  messagingSenderId: "175919385938"
};

const config = devEnvironment ? devConfig : prodConfig;

const cors = require('cors')({ origin: true });
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const db = firebase.database();
const db1 = firebase.firestore();

export {
  db,
  auth,
  db1,
  config,
};
