//Testing Pull Requests
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import Navigation from './Navigation';
import LandingPage from './Landing';
import SignUpPage from './SignUp';
import SignInPage from './SignIn';
import PasswordForgetPage from './PasswordForget';
import HomePage from './Home';
import AccountPage from './Account';
import PatientsPage from './Patients';
import ImageViewer from './ImageViewer';

import * as routes from '../constants/routes';

import withAuthentication from './withAuthentication';

let uriPatientId = null;
let uriPractitionerId = null;

const App = () =>
  <Router>
    <div>
      <Navigation />

      <hr/>

      <Route exact path={routes.LANDING} component={LandingPage} />
      <Route exact path={routes.SIGN_UP} component={SignUpPage} />
      <Route exact path={routes.SIGN_IN} component={SignInPage} />
      <Route exact path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route exact path={routes.HOME} component={HomePage} />
      <Route exact path={routes.ACCOUNT} component={AccountPage} />
      <Route exact path={routes.PATIENTS} component={PatientsPage} />
      <Route exact path={routes.IMAGE_VIEWER} component={ImageViewer} />
    </div>
    <div id="flo1" />
    <div id="flo2" />
    <div id="flo3" />

    <div className="versionNumber">v2.7.2</div>
    
  </Router>

export default withAuthentication(App);
