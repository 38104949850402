import React from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { db } from '../firebase';
import firebase from "firebase";

let uriPatientId = null;
let uriPractitionerId = null;

class LandingPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false
    };

    const url = new URL(window.location.href);
    uriPatientId = url.searchParams.get('Patient_ID');
    uriPractitionerId = url.searchParams.get('Practitioner_ID');

  }

  

  async componentDidMount() {
    if (uriPatientId !== null)
      this.importTraknetPatient();
  
  }

  importTraknetPatient = async () => {
    debugger;
    let patientId = await db.getTracknetPatientId(uriPatientId);
    if (patientId == null) {
        //if patientId is null they either don't exist, or we've already set uriPatientId to -1 after importing them
            let userId = await db.getTracknetUserId(uriPractitionerId);
            let currentUserId = db.getCurrentUserId();
            
            //If userId is not found then the Practitioner hasn't signed up yet
            if(userId == null){
              window.location.href = "https://medipics.net/" + window.location.search;
            }
            
            if (userId !== null && userId === currentUserId) {
                const loadingMessage = document.getElementById('loadingPatientMessage');
                loadingMessage.style.display = "block";
                var importTracknetRequest = firebase.functions().httpsCallable('importTraknetUser', {timeout: 540000});
                await importTracknetRequest({ patientId: uriPatientId, userId: db.getCurrentUserId()})
                    .then((result) => {
                        patientId = result.data.patientDbId;
                        if (patientId === -1)
                            throw 'Could not import traknet user';
                    }).catch((error) => {
                        alert('Could not import the traknet user');
                    });
                    loadingMessage.style.display = "none";
            }
    }

    if (patientId !== null){
      window.location.href = "image-viewer" + window.location.search;
    }
    //uriPatientId = "-1"; //set this after first run so we don't try to populate the dropdown to a patient again (when clearing images)
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    return (
      <div className="container">
        <h3>Welcome to Medipics</h3>
        <span style={{display: "none"}}>Please Sign In to continue.</span>
        <div style={{display: "none"}} id="loadingPatientMessage"><h3>Please wait while we load the patient...</h3>
          <div className="loader">Loading...</div>
        </div>
      </div>);
    }
  }

export default LandingPage;
