import React, { Component } from "react";
import firebase from "firebase";
import { db } from '../firebase';
require("firebase/functions");

class ImageDownloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      firebase.functions().useFunctionsEmulator('http://localhost:5001')
    }

    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    const downloadLink = document.getElementById('downloadLink');
    downloadLink.href = '';
    downloadLink.innerText = '';
  }

  _handleClick = (event) =>
  {
    const downloadButton = document.getElementById('downloadButton');
    const downloadSpinner = document.getElementById('loadingSpinner');
    downloadButton.disabled = true;
    var x = this.props.patientId;
    var y = this.props.visitId;
    downloadSpinner.style.display = "block";

    var fileDownloader = firebase.functions().httpsCallable('fileDownloader', {timeout: 540000});

      //For the fullName we have defined that fullName takes some data as a parameter 
      fileDownloader({
      userId: db.getCurrentUserId(),
      patientId: this.props.patientId,
      visitId: this.props.visitId
    }).then((result)=> {
      console.log(result);
      setTimeout(function() {
      var fileDownloadUrlRef = firebase.storage().ref('/' + result.data.fileName);
      fileDownloadUrlRef.getDownloadURL().then((url) => { 
        console.log("The Download URL is: " + url);
        var downloadLink = document.getElementById('downloadLink');

        downloadLink.href = url;
        downloadLink.innerText = ' Click Here To Download Archive';
        downloadButton.disabled = false;
        downloadSpinner.style.display = "none";
      });
    }, 3000);
            }).catch((error)=> {
      console.log(error);
      alert("An error occurred downloading archive: " + error);
    })
  }

  render() {
    return (
      <div>
      <div>
        <h3>Download Archive <span className="glyphicon glyphicon-cloud-download" aria-hidden="true"></span></h3>
      </div>
      <div>
      <form>
          <label>Export Images: </label><br />
          <span>This might take several minutes, please wait until download link appears.</span><br />
          <button style={{display: "inline"}} className="btn btn-default" id="downloadButton" onClick={this._handleClick}>
            Archive Selected Patient&nbsp;
          </button>
          <div style={{display: "none"}} id="loadingSpinner" className="loader">Loading...</div>
          <a id="downloadLink"></a>
        </form>
      </div>
      </div>
    );
  }
}

export default ImageDownloader;