import React from 'react';

import AuthUserContext from './AuthUserContext';
import AddPatientForm from './AddPatient';
import withAuthorization from './withAuthorization';

const PatientsPage = () =>
  <AuthUserContext.Consumer>
    {authUser =>
      <div className="container">
        
        <AddPatientForm />
      </div>
    }
  </AuthUserContext.Consumer>

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(PatientsPage);
