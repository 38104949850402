import React, { Component } from 'react';

import { authenticate } from '../firebase';
import AuthUserContext from './AuthUserContext';
import withAuthorization from './withAuthorization';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  passwordThree: '',
  error: null,
};

const SUCCESS_SEND = {
  passwordOne: '',
  passwordTwo: '',
  passwordThree: '',
  error: null,
  success: 'Your password has been reset.'
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.oldPasswordRef = React.createRef();
  }

  async componentDidMount() {
    this.oldPasswordRef.current.focus();
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;
    const { passwordThree } = this.state;

    authenticate.doPasswordUpdate(passwordThree, passwordOne)
      .then(() => {
        this.setState({ ...SUCCESS_SEND });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      passwordOne,
      passwordTwo,
      passwordThree,
      error,
      success,
    } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

    return (
        <div id="change_password_form" className="panel panel-primary">
          <div className="panel-heading">
            <h3 className="panel-title">Change Password</h3>
          </div>
          <div className="panel-body">
          <AuthUserContext.Consumer>
          {authUser =>
            <p>Account: {authUser.email}</p>
          }
        </AuthUserContext.Consumer>
            <form onSubmit={this.onSubmit}>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Old Password:</label>
                <div className="col-sm-8">
                  <input
                    ref={this.oldPasswordRef}
                    className="form-control"
                    value={passwordThree}
                    onChange={event => this.setState(byPropKey('passwordThree', event.target.value))}
                    type="password"
                    placeholder="Old password"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">New Password:</label>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    value={passwordOne}
                    onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
                    type="password"
                    placeholder="New password"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Confirm Password</label>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    value={passwordTwo}
                    onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
                    type="password"
                    placeholder="Confirm new password"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="offset-sm-2 col-sm-10">
                  <button className="btn btn-primary" disabled={isInvalid} type="submit">
                    Reset My Password
        </button>
                </div>
              </div>
              {error && <p><br></br>{error.message}</p>}
        {success && <p><br></br>{success}</p>}
            </form>
          </div>
        </div>
    );
  }
}

export default PasswordChangeForm;
