import { db } from './firebase';
import { db1 } from './firebase';
import { authenticate } from '../firebase';
import { auth } from './firebase';
import firebase from 'firebase/app';

// Patient API

export const doCreateUser = (id, test) =>
  db.ref(`users/${id}`).set({
    test
  });

var onPatientImageChange;
var onVisitImageChange;

export async function onceGetUserSubscription() {
  const id = auth.currentUser.uid;
  var userSubscriptionReference = await db1.collection("users").doc(id);

  var userSubscription = await userSubscriptionReference.get().then(function(doc) {
    if (doc.exists) {
        return doc.data().subscription;
    } else {
        return null;
    }
  }).catch(function(error) {
      console.log("Error getting document:", error);
  });

  return userSubscription;
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export async function addNewVisit(patientId, timestamp){
  let visitId = -1;
  let visit = null;
  let tstamp = new Date(timestamp * 1000).toLocaleDateString();

  await db1.collection("patients").doc(patientId).collection("visits").get()
  .then((visitSnapshot) => {
      if (visitSnapshot !== null && visitSnapshot !== undefined) {
        visitSnapshot.forEach((patientVisitDoc) => {
          visit = patientVisitDoc.data();
          if (tstamp === new Date(visit.timestamp * 1000).toLocaleDateString())
            visitId = patientVisitDoc.id;
      });
    }
  });

  if (visitId !== -1)
    return visitId;

  await db1.collection("patients").doc(patientId).collection("visits").add({
    timestamp: timestamp,
  })
  .then(function(docRef) {
      console.log("Document written with ID: ", docRef.id);
      visitId = docRef.id;
  })
  .catch(function(error) {
      console.error("Error adding document: ", error);
  });

  return visitId;
}

export async function addImageToVisit(patientId, visitId, title, imageUrl, timestamp)
{
  db1.collection("patients").doc(patientId).collection("visits").doc(visitId).collection("photos").doc(uuidv4()).set({
    title: title,
    url: imageUrl,
    timestamp: timestamp
  })
  .then(function() {
      console.log("Document successfully written!");
  })
  .catch(function(error) {
      console.error("Error writing document: ", error);
  });
}

export async function getPatientVisits(patientId) {
  var patientVisits = [];
  var visitNumber = 0;
  if (patientId !== null && patientId !== "") {
    // Get visit data
    await db1.collection("patients").doc(patientId).collection("visits").orderBy("timestamp", "desc").get().then(async function(visitSnapshot){
      visitSnapshot.forEach((patientVisitDoc) => {
        var patientVisitData = patientVisitDoc.data();
        patientVisitData.id = patientVisitDoc.id;
        patientVisitData.visitNumber = visitNumber++;
        patientVisits.push(patientVisitData);
      });
    });
  }

  return patientVisits;
}

export async function getVisitImages(patientId, visitId) {
  let visitPhotos = [];
  if (visitId !== undefined && visitId !== -1)
  {
    var visitQuery = await db1.collection("patients").doc(patientId).collection("visits").doc(visitId).get();
    var visit = visitQuery.data();
    var visits = await getPatientVisits(patientId);

    await db1.collection("patients").doc(patientId).collection("visits").doc(visitId).collection("photos").orderBy("timestamp", "desc").get().then(function(photosSnapshot){
      photosSnapshot.forEach(function(photoSnapshot){
        var visitPhoto = photoSnapshot.data();
        var visitNumber = visits.filter(obj => { return obj.timestamp === visit.timestamp })[0].visitNumber;
        visitPhoto.visitId = visit.id;
        visitPhoto.visitNumber = visits.length - 1 - visitNumber;
        visitPhoto.visitDate = visit.timestamp;
        visitPhotos.push(visitPhoto);
      });
    });
  }
  else
  {
    var visits = await getPatientVisits(patientId);
    for (var i = 0; i < visits.length; i++) {
      await db1.collection("patients").doc(patientId).collection("visits").doc(visits[i].id).collection("photos").orderBy("timestamp", "desc").get().then(function(photosSnapshot){
        photosSnapshot.forEach(function(photoSnapshot){
          var visitPhoto = photoSnapshot.data();
          visitPhoto.visitId = visits[i].id;
          visitPhoto.visitNumber = visits.length - 1 - visits[i].visitNumber;
          visitPhoto.visitDate = visits[i].timestamp;
          visitPhotos.push(visitPhoto);
        });
      });
    }
  }

  return visitPhotos;
}

export function getCurrentUserId(){
  if(auth != null && auth.currentUser != null)
  {
    return auth.currentUser.uid;
  }else{
    return null;
  }
  
}

export async function getCurrentUser(){
  let currentPatient = null;

  const id = auth.currentUser.uid;
  let currentPatientReference = await db1.collection("users").doc(id);

  currentPatient = await currentPatientReference.get().then(function(doc) {
    if (doc.exists) {
        return doc.data();
    } else {
        return null;
    }
  }).catch(function(error) {
      console.log("Error getting document:", error);
  });

  return currentPatient;
}

export async function getTracknetUserId(practitionerID) {
  let result = null;
  let querySnapshot = await db1.collection("users").where('traknet.practitionerID', '==', practitionerID).get();

  if (!querySnapshot.empty)
    result = querySnapshot.docs[0].id;

  return result;
}

export async function getTracknetPatientId(tracknetPatientId) {
  let result = null;
  debugger;
  if((typeof tracknetPatientId === 'string' || tracknetPatientId instanceof String)){
    let patientIdUpperAndLowercase = [tracknetPatientId.toUpperCase(), tracknetPatientId.toLowerCase()];
    let querySnapshot = await db1.collection("patients").where('traknet.patientId', 'in', patientIdUpperAndLowercase).get();

  if (!querySnapshot.empty)
    result = querySnapshot.docs[0].id;
  }

  return result;
}

export async function onceGetAllPatients() {
  const userId = auth.currentUser.uid;
  var user = await db1.collection("users").doc(userId).get();
  var patientList = [];

  if (user.exists) {
    await db1.collection("patients").where("userID", "==", userId).get().then(function(querySnapshot) {
      querySnapshot.forEach(function(patient) {
        var patientData = patient.data();
        patientData.patientDbId = patient.id;
        patientList.push(patientData);
      });
    });
  }
  return patientList;
}
