import React from 'react';

import { authenticate } from '../firebase';

const SignOutButton = () =>
  <button
    className="btn btn-primary navbar-btn pull-right sign-out"
    type="button"
    onClick={authenticate.doSignOut}
  >
    <span className="glyphicon glyphicon-log-out" aria-hidden="true"></span>
    &nbsp;Sign Out
  </button>

export default SignOutButton;
