import React from 'react';
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
  } from "react-image-magnifiers";

  const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
  });


  let settingValues = {};
  let settingMin = {};
  let settingMax = {};
  let settingIncrement = {};
  let sourceImage, targetRoot, maState;
class ImageView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fullScreen: "panel panel-primary",
            magnifierSize: "400px",
        }
    }

    handleMagnifierSize = (e,id) => {

        let magnifier = document.getElementById("imageContainer" + id).querySelector('img').nextSibling;
        //let magnifier = document.getElementById("imageContainer" + id).firstChild;
        
        if(e == 0){
            magnifier.style.display = "none";
        }else{
            magnifier.style.display = "inline";
            const value = e + "%";
            this.setState(() => ({magnifierSize: value }));
        }
    }

    toggleMagnifier(id){
        let magnifier = document.getElementById("imageContainer" + id).querySelector('img').nextSibling;
        let toggleButton = document.getElementById("panel-toggle-magnifier_" + id);
        
        if(magnifier.style.display == "none"){
            magnifier.style.display = "inline";
            toggleButton.className = "glyphicon glyphicon-zoom-out";
            toggleButton.parentElement.title = "Disable Magnifier";
        }else{
            magnifier.style.display = "none";
            toggleButton.className = "glyphicon glyphicon-zoom-in";
            toggleButton.parentElement.title = "Enable Magnifier";
        }
    }

    toggleFullscreen(id) {

        let toggleButton = document.getElementById("panel-toggle-fullscreen_" + id);

        if (this.state.fullScreen === "panel panel-primary") {
            this.setState({ fullScreen: "panel panel-primary panel-fullscreen" });
            toggleButton.className = "glyphicon glyphicon-resize-small";
        } else {
            toggleButton.className = "glyphicon glyphicon-resize-full";
            this.setState({ fullScreen: "panel panel-primary" });
        }
    }

    increaseLevel(id, type) {
        if (!(type + id in settingValues)){
            if (type == "brightness")
                settingValues[type + id] = 1;
            
            if (type == "contrast")
                settingValues[type + id] = 0;
        }

        let imageElement = document.getElementById("imageContainer" + id).querySelector('img');
        let getValue = settingValues[type + id];
        getValue += .1;
        settingValues[type + id] = getValue;
        imageElement.style.filter = type + "(" + getValue + ")";
    }

    decreaseLevel(id, type) {
        if (!(type + id in settingValues)){
            if (type == "brightness")
                settingValues[type + id] = 1;
            
            if (type == "contrast")
                settingValues[type + id] = 0;
        }

        let imageElement = document.getElementById("imageContainer" + id).querySelector('img');
        let getValue = settingValues[type + id];
        getValue -= .1;
        settingValues[type + id] = getValue;
        imageElement.style.filter = type + "(" + getValue + ")";
    }

    render() {
        const { fullScreen } = this.state;
        const { magnifierSize } = this.state;
        return (
            <div className="col-md-4">
                <div className={fullScreen}>
                    <div className="panel-heading" style={{userSelect: "none"}}>
                        <ul className="list-inline" style={{marginRight: "-10px", marginLeft: "0px", float: "right"}}>
                        <li><a onClick={() => this.toggleFullscreen(this.props.imageUrl.slice(-8))} role="button" title="Toggle Fullscreen"><i id={"panel-toggle-fullscreen_" + this.props.imageUrl.slice(-8)} className="glyphicon glyphicon-resize-full" style={{color: "#333"}}></i></a></li>
                        <li><a onClick={() => this.props.action_remove(this.props.imageUrl, this)} role="button" title="Toggle Fullscreen"><i id={"panel-toggle-fullscreen_" + this.props.imageUrl.slice(-8)} className="glyphicon glyphicon-remove" style={{color: "#333"}}></i></a></li>
                        </ul>
                        <ul className="list-inline" style={{marginRight: "10px",  float: "right"}}>
                            <li><a onClick={() => this.toggleMagnifier(this.props.imageUrl.slice(-8))} role="button" title="Disable Magnifier"><i id={"panel-toggle-magnifier_" + this.props.imageUrl.slice(-8)} className="glyphicon glyphicon-zoom-out"  style={{color: "#333"}}></i></a></li>
                            <span style={{display: "none"}}>Brightness:</span>
                            <li><a id="panel-brightness-decrease" onClick={() => this.decreaseLevel(this.props.imageUrl.slice(-8), "brightness")} role="button" title="Decrease Brightness"><i className="glyphicon glyphicon-minus" style={{color: "#333"}}></i></a></li>
                            <li><a id="panel-brightness-increase" onClick={() => this.increaseLevel(this.props.imageUrl.slice(-8), "brightness")} role="button" title="Increase Brightness"><i className="glyphicon glyphicon-plus" style={{color: "#333"}}></i></a></li>
                        </ul>
                        <ul className="list-inline" style={{marginRight: "10px",  float: "right"}}>
                        <li style={{display: "none"}}>
                            Magnifier:
                            <select defaultValue="30" onChange={event => this.handleMagnifierSize(event.target.value, this.props.imageUrl.slice(-8))} style={{color: "#000000", borderRadius: "3px", marginLeft: "5px"}}>
                                <option value="0">Off</option>
                                <option value="10">10%</option>
                                <option value="20">20%</option>
                                <option value="30">30%</option>
                                <option value="40">40%</option>
                                <option value="50">50%</option>
                                <option value="60">60%</option>
                                <option value="70">70%</option>
                                <option value="80">80%</option>
                                <option value="90">90%</option>
                                <option value="100">100%</option>
                            </select>
                        </li>
                        </ul>
                        <div style={{width: "50%", float: "left", height: "80px", display: "contents"}}>
                            {this.props.imageTitle}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="well well-sm" id={"imageContainer" + this.props.imageUrl.slice(-8)}>
                            <GlassMagnifier
                                imageSrc={this.props.imageUrl}
                                imageAlt="Example"
                                magnifierSize={magnifierSize}
                                />
                            <input type="hidden" id={"brightnessId" + this.props.imageUrl.slice(-8)} value="1" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageView;