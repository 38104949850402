import React, { Component } from 'react';

import withAuthorization from './withAuthorization';
import Visits from './Visits';
import { db } from '../firebase';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patients: null,
    };
  }

  componentDidMount() {
    db.onceGetAllPatients().then(patients => {
      this.setState({ patients: patients })
    })
  }

  changeRoute(acc) {
    console.log(acc)
  }

  render() {
    const { patients } = this.state;
    
    return (
      <div className="container">
        { !!patients && 
          <div>
          <h2>My Patients</h2>
          {Object.keys(patients).map(key =>
            <div className="panel panel-primary" key={"patient" + patients[key].accountNumber} onClick={() => this.changeRoute(patients[key].accountNumber)}>
              <div className="panel-heading">{patients[key].lastName}, {patients[key].firstName}</div>
              <div className="panel-body">
              <p>Date of Birth: {patients[key].birthdate}</p>
              <Visits key={"patient_visit" + patients[key].accountNumber} patientId={patients[key].accountNumber} visitList={patients[key].visits} />
              </div>
            </div>
          )}
          </div>
        }
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(HomePage);
