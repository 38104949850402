import React from 'react';
import ReactCompareImage from 'react-compare-image';

class ImageCompare extends React.Component {
    render(){
        return (
            <div className="col-md-8">
                <div className="panel panel-primary">
                    <div className="panel-heading">
                        Image Compare <span className="glyphicon glyphicon-transfer" aria-hidden="true"></span>
                    </div>
                    <div className="panel-body">
                        <ReactCompareImage leftImage={this.props.leftImageUrl} rightImage={this.props.rightImageUrl} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageCompare;